<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AppDocumentLayout',
  components: {},
  beforeRouteUpdate(to, from, next) {
    this.modifyLeftBarDropdown(to.name)
    next()
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('auth', ['isOwner']),
  },
  created() {
    this.setLeftbarContent({
      back: '',
      customeHeader: 'left-bar-class-list',
      defaultRoute: 'web-document',
      routes: [
        {
          id: '01',
          name: 'FEE_MANAGER_DOCUMENTATION',
          role: ['super_admin', 'section_teacher', 'section_student', 'campus_admin'],
          route: { name: 'fee-documentation' },
          routesName: ['fee-documentation', 'fee-question'],
        },
        {
          id: '02',
          name: 'SYLLABUS_AUTOMATER_DOCUMENTATION',
          role: ['super_admin', 'section_teacher', 'section_student', 'campus_admin'],
          route: { name: 'syllabus-manager-documentation' },
          routesName: ['syllabus-manager-documentation', 'syllabus-question'],
        },
        {
          id: '03',
          name: 'EXAM_PLANNER',
          role: ['super_admin', 'section_teacher', 'section_student', 'campus_admin'],
          route: { name: 'exam-planner-documentation' },
          routesName: ['exam-planner-documentation', 'exam-question'],
        },
      ],
    })
  },
  methods: {
    ...mapActions('layout', ['setLeftbarContent', 'modifyLeftBarDropdown']),
  },
}
</script>
